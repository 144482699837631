<template>
  <div class="qtype-form display--flex">
    <div class="card qtype-form__staff">
      <div class="card-header card-header-divider">Тип вопросов</div>
      <div class="card-body">
        <form action="#" @submit.prevent="submitQuestionType" novalidate>
          <div class="qtype-form__inputs display--flex justify-content-center">
            <div class="qtype-form__col1">
              <custom-input v-model="form.name" label="Имя" name="name" req />
              <custom-color-picker
                v-model="form.color"
                label="Цвет"
                name="color"
                placeholder="Число в HEX-формате"
              />
            </div>
          </div>
          <div
            class="
              qtype-form__btns
              mt-25
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <router-link to="/test/question-type" class="btn btn-w"
              >Назад</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "../../../mixins/useErrors";
import { reactive } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import CustomInput from "../../../components/Forms/Fields/CustomInput.vue";
import CustomColorPicker from "../../../components/Forms/Fields/CustomColorPicker.vue";
import standartError from "../../../mixins/standartError";

export default {
  components: { CustomInput, CustomColorPicker },
  name: "qtype-form",
  setup() {
    const store = useStore(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();
    const questionType = computed(() => store.state.test.questionType);
    const form = reactive({
      name: "",
      color: null,
    });

    onBeforeMount(() => {
      if (route.params.id) {
        store.dispatch("test/getQuestionType", route.params.id).then(() => {
          form.name = questionType.value.name;
          form.color = questionType.value.color;
        });
      }
    });

    let submitQuestionType = () => {
      if (route.params.id) {
        store
          .dispatch("test/updateQuestionType", { ...form })
          .catch(standartError(setErrors, clearCustomErrors));
      } else {
        store
          .dispatch("test/createQuestionType", { ...form })
          .catch(standartError(setErrors, clearCustomErrors));
      }
    };

    return {
      form,
      submitQuestionType,
    };
  },
};
</script>

<style lang="scss" scoped>
.qtype-form {
  padding: 0 0 10px 0;
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  .form-group {
    margin: 35px -15px;
  }

  .card-body {
    padding: 20px 50px;
  }

  &__col1 {
    flex: 1;
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 973px) {
    .form-group {
      margin: 15px -15px;
    }
  }
}
</style>